<template>
    <div class="wrap">
        <div class="iconBox">
            <img src="../../assets/newegg/image/new-return.png" alt="" class="rule"  @click="close">
            <div class="balance">
                <img src="../../assets/newegg/image/new-gold.png" alt="" class="balance-icon">
                <span>橙币：</span>
                <span>{{ gold }}</span>
            </div>
            <img src="../../assets/newegg/image/new-help.png" alt="" class="closeGame" @click="showRule = true" >
        </div>
        <div class="box">
            <div class="copperEgg">
            
                <div class="copper-money">{{dataObj.copper.price}}橙币</div>
                <img :src="activeImg" alt="" class="activeCp" v-show="catchDom == 'copper' && isShowImg">
                <img :src="tdImg" alt="" class="eggs" id="copper" @click="play('copper')">
               
            </div>

            <div class="goldEgg">
                <div class="gold-money">{{dataObj.gold.price}}橙币</div>
                <!-- <div class="e4e261">{{ dataObj.gold.price }}</div> -->
                <img :src="activeImg" alt="" class="activeGl" v-show="catchDom == 'gold' && isShowImg">
                <img :src="jdImg" alt="" class="eggs" id="gold" @click="play('gold')">
                <!-- <img :src='bottIcon' alt="" class="dock"> -->
            </div>

            <div class="silverEgg">
                <div class="silver-money">{{dataObj.silver.price}}橙币</div>
                <img :src="activeImg" alt="" class="activeSl" v-show="catchDom == 'silver' && isShowImg">
                <img :src="ydImg" alt="" class="eggs" id="silver" @click="play('silver')">
            </div>
            
            <div class="btns">
                <div @click="batchPlay('one')" class="playBtn" :class="{one:oneImg}">
                    <span >砸1次</span>
                </div>
                <div @click="batchPlay('five')" class="playBtn" :class="{hundred:hundredImg}">
                    <span >砸5次</span>
                </div>
                <div @click="batchPlay('ten')" class="playBtn" :class="{ten:tenImg}">
                    <span >砸10次</span>
                </div>
            </div>
        </div>

        <div class="bgGIf" v-if="showEggGif">
            <!-- <div class="bgGIf" v-if="true">
            <img src="../../assets/newegg/image/jd_bg_gif.gif" alt="" class="gifAnimation"> -->
            <img :src="bgGIf[catchDom]" alt="" class="gifAnimation">
        </div>

        <audio id="audio" ref="audio">
            <!-- <source src="https://storage.wnwdkj.cn/egg_open.wav" type="audio/wav"> -->
            <source src="../../assets/newegg/video/egg_open.wav" type="audio/wav">

        </audio>
        <rule :content="dataObj['gold']['remark']" v-show="showRule" @close="showRule = false" />
        <prize v-if="showPrize" :prize="prize" @close="reset" />
    </div>
</template>

<script>
import '@/assets/newegg/css/index.css'
import rule from "@/components/rule";
import prize from "@/components/prize";
import { Toast } from "vant";

import tdImg from '../../assets/newegg/image/new-egg-one.png'
import jdImg from '../../assets/newegg/image/new-egg-two.png'
import ydImg from '../../assets/newegg/image/new-egg-three.png'
import bottIcon from '../../assets/newegg/image/bottIcon.png'

import jdBgGif from '../../assets/newegg/image/new-two.gif'
import ydBgGif from '../../assets/newegg/image/new-three.gif'
import tdBgGif from '../../assets/newegg/image/new-one.gif'

import activeImg from '../../assets/newegg/image/new-hammer.png'

export default {
    components: {
        prize,
        rule
    },
    data() {
        return {
            showRule: false,
            showPrize: false,
            gold: 0,
            prize: null,
            tdImg,
            jdImg,
            ydImg,
            bottIcon,
            relevanceObj: {
                copper: tdImg,
                gold: jdImg,
                silver: ydImg,
            },
   
            catchDom: 'gold',
            requestCompleted: true,
            oneImg: true,
            hundredImg: false,
            tenImg: false,
            num: 'one',
            dataObj: {
                copper: {},
                gold: {},
                silver: {},
            },
            activeImg,
            isShowImg: true,
            showEggGif: false,
            bgGIf: {
                gold: jdBgGif,
                silver: ydBgGif,
                copper: tdBgGif,
            }
        }
    },
    mounted() {
        this.getInfo()
    },
    watch: {
        'globalData.XPLATFORM': {
            handler(newVal) {
                newVal && this.getInfo()
            },
        }
    },
	
    methods: {
        getInfo() {
            if (this.globalData.XPLATFORM) {
                this.$http.get('lottery-game/info', {
                    params: { code: 'gold' },
                    headers: {
                        'X-PLATFORM': this.globalData.XPLATFORM
                    }
                }).then((res) => {
                    console.log(res,'gold')
                    this.dataObj.gold = res.data
                    this.gold = res.data['gold']
                });
                this.$http.get('lottery-game/info', {
                    params: { code: 'copper' },
                    headers: {
                        'X-PLATFORM': this.globalData.XPLATFORM
                    }
                }).then((res) => {
                    this.dataObj.copper = res.data
                    this.gold = res.data['gold']
                });
                this.$http.get('lottery-game/info', {
                    params: { code: 'silver' },
                    headers: {
                        'X-PLATFORM': this.globalData.XPLATFORM
                    }
                }).then((res) => {
                    this.dataObj.silver = res.data
                    this.gold = res.data['gold']
                });
            }
        },
        reset() {
            this.$refs.audio.pause();
            $('#' + this.catchDom).attr('src', this.relevanceObj[this.catchDom])
            this.showPrize = false;
            this.isShowImg = true;
            this.requestCompleted = true;
            this.getInfo()
        },
        play(dom) {
            this.isShowImg = false
            const limit = this.num == 'one' ? 'price' : this.num + '_price';
            if (parseInt(this.gold) < parseInt(this.dataObj[dom][limit])) {
                return Toast.fail({ message: '余额不足', position: 'bottom' })
            };
            this.catchDom = dom
            // $('#' + dom).attr('src', this.gifObj[dom]);
            this.showEggGif = true
           
            this.getData(this.num, dom)
        },
        batchPlay(type) {
            if (type == 'one') {
                this.num = 'one';
                this.oneImg = true;
                this.hundredImg = false;
                this.tenImg = false;
            }
            else if (type == 'five') {
                this.num = 'five';
                this.oneImg = false;
                this.hundredImg = true;
                this.tenImg = false;
            } else if (type == 'ten') {
                this.num = 'ten';
                this.oneImg = false;
                this.hundredImg = false;
                this.tenImg = true;
            };
            // if (parseInt(this.gold) < parseInt(this.game[num])) {
            //     return Toast.fail({ message: '余额不足', position: 'bottom' })
            // };
            // $('#' + this.catchDom).attr('src', this.gifObj[this.catchDom])
            // this.getData(type)

        },
        getData(type, dom) {
            if (!this.globalData.XPLATFORM) {
                return
            }
            if (!this.requestCompleted) {
                return
            };
            this.requestCompleted = false;
            const that = this
            this.$refs.audio.load();
            this.$refs.audio.play();
            this.$refs.audio.playbackRate = 0.5;
            this.$http.post('lottery-game/draw', {
                type, code: dom, room_id: that.globalData.roomId,
            }, {
                headers: { 'X-PLATFORM': this.globalData.XPLATFORM }
            }).then((res) => {
                this.prize = res;
                setTimeout(() => {
                    that.showEggGif = false
                    that.showPrize = true;
                    this.$refs.audio.playbackRate = 1;
                }, 1800);
            }).catch(e => {
                this.reset()
            });

        }
    }
}
</script>

<style scoped></style>
